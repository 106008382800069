import React, { memo, useCallback, useRef, useState } from "react";
import { useDrag } from "react-use-gesture";

import RecordIcon from "astrid-components/lib/components/Assets/Icons/Record";
import Pdf from "astrid-components/lib/components/Assets/Pdf";
import List from "astrid-components/lib/components/Data/List";
import Button from "astrid-components/lib/components/Inputs/Button";
import Flex from "astrid-components/lib/components/Layout/Flex";
import Swipe from "astrid-components/lib/components/Modules/Swipe";
import Text from "astrid-components/lib/components/Text/Text";
import msToTime from "astrid-helpers/src/msToTime";

import * as firebase from "../../helpers/firebase";
import nudgeTimeline from "../../helpers/nudgeTimeline";
import selectMarker from "../../helpers/selectMarker";
import useCanRecord from "../../hooks/useCanRecord";
import useText from "../../hooks/useText";
import { startRecorder, stopRecorder } from "../../services/recorder";
import { jumpToPosition } from "../../services/timeline";
import { isAction } from "../../state/action";
import { clips } from "../../state/clips";
import { useEditor, useRecorder } from "../../state/permissions";
import { useSelectedMarker } from "../../state/selectedMarker";

import PageButton from "./Common/PageButton";

function Record({ ad }) {
	const [isRecording, setIsRecording] = useState();

	const onDrag = useDrag(
		useCallback(
			async ({ tap, event }) => {
				event.stopPropagation();

				if (!tap) {
					return;
				}

				if (isRecording) {
					stopRecorder();
				} else {
					setIsRecording(true);

					const updates = [];
					const { position, clipId } = ad;

					jumpToPosition(position);

					if (ad.clip) {
						updates.push(ad.clip.remove());
					}

					const { length, precording } = await startRecorder({
						silent: true,
						position: position - 100,
						recordPosition: position,
					});

					const { clip } = precording;

					if (clip) {
						updates.push(ad.update({ clipId: clip.id }));

						if (ad.id === "pre") {
							const rightClip = clips.toRight(0, [clipId, clip.id]);

							if (rightClip) {
								updates.push(
									nudgeTimeline(0, 5000 - (rightClip.position - (position + length)), [clip.id]),
								);
							}
						}

						precording.undo = () => firebase.commit(ad.update({ clipId }));
						firebase.commit(updates);
					}

					setIsRecording(false);
				}
			},
			[ad, isRecording],
		),
		// { filterTaps: true },
	);

	return (
		<Button transparent size="tiny" active={isRecording} color="negative" {...onDrag()}>
			<RecordIcon size={14} />
		</Button>
	);
}

function Ad({ ad, ...props }) {
	const ref = useRef();
	const t = useText();
	const editor = useEditor();
	const recorder = useRecorder();
	const canRecord = useCanRecord();
	const selectedAd = useSelectedMarker();

	const selected = selectedAd && selectedAd[0] === ad.id;

	const onClick = useCallback(() => {
		Pdf.setSearchIndex();

		selectMarker(!selected && ad.id);
		if (!isAction("record")) jumpToPosition(ad.position);
	}, [ad.id, ad.position, selected]);

	const onSwipe = useCallback(
		(value) => {
			let returnValue = ad.swipe;

			if (value > 30) {
				returnValue = ad.stage();
				firebase.commit(ad);
			} else if (value < -30) {
				if (!ad.checked) {
					selectMarker();
				}

				returnValue = ad.check();
				firebase.commit(ad);
			}

			return returnValue;
		},
		[ad],
	);

	const right = !ad.checked && (editor || (recorder && ad.staged));
	const left = !ad.staged && recorder;

	const labels = {
		pre: t("intro", "Intro"),
		post: t("outro", "Outro"),
	};

	return (
		<List.Item ref={ref} {...props}>
			<Swipe
				height={60}
				left={left}
				right={right}
				x={ad.swipe}
				onChange={onSwipe}
				onClick={!ad.checked && onClick}
			>
				<List.Item first active={selected}>
					<Flex height={45} padding="0 10px" cursor="pointer">
						<PageButton>{ad.page}</PageButton>
						<List.Text>{labels[ad.id]}</List.Text>
						<Flex marginLeft="auto">
							<Button transparent size="tiny" textSize="small" color={ad.color}>
								{msToTime(ad.position, false)}
							</Button>

							{ad.staged && canRecord && <Record ad={ad} />}
						</Flex>
					</Flex>
					{selected && ad.text && (
						<div style={{ padding: "0 20px 10px 62px" }}>
							<Text>{ad.text}</Text>
						</div>
					)}
				</List.Item>
			</Swipe>
		</List.Item>
	);
}

export default memo(Ad);
